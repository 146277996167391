// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import {decrypt, encrypt} from "../utility/Utils"

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(decrypt(item)) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      state[config.storageExpiredToken] = Math.floor((Date.now() + (3600 * 1000)) / 1000)
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
      localStorage.setItem(config.storageExpiredToken, Math.floor((Date.now() + (3600 * 1000)) / 1000))
    },
    handleDetail: (state, action) => {
      state.userData = action.payload
      localStorage.setItem('userData', encrypt(JSON.stringify(action.payload)))
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      state[config.storageExpiredToken] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
      localStorage.removeItem(config.storageExpiredToken)
    }
  }
})

export const { handleLogin, handleDetail, handleLogout } = authSlice.actions

export default authSlice.reducer
