// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
// import profile from '@src/views/userManagement/profile/store'
// import userGroup from '@src/views/userManagement/userGroup/store'
import brand from '@src/views/brand/store'
import partner from '@src/views/partner/store'
import services from '@src/views/services/store'
import portfolio from '@src/views/portfolio/store'
import companyProfile from '@src/views/company-profile/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  brand,
  services,
  portfolio,
  companyProfile,
  partner
}

export default rootReducer
