// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import url from "../../../configs/pathConfig"

export const getAllData = createAsyncThunk('appServices/getAllData', async () => {
  // const response = await axios.get('/api/users/list/all-data')
  const response = await axios.get(`${url.be}/list`)
  return response.data
})

export const getData = createAsyncThunk('appServices/getData', async params => {
  const response = await axios.get(`${url.be}/service`, {params})
  return {
    params,
    data: response.data,
    totalPages: response.data
  }
})

export const getDetail = createAsyncThunk('appBrand/getJob', async id => {
  // const response = await axios.get('/api/users/profile', { id })
  const response = await axios.get(`${url.be}/service/${id}`)
  return response.data
})

export const appServicesSlice = createSlice({
  name: 'appServices',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedServices: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getAllData.fulfilled, (state, action) => {
          state.allData = action.payload
        })
        .addCase(getData.fulfilled, (state, action) => {

          state.data = action.payload.data.data.outputList
          state.params = action.payload.params
          state.totalPages = action.payload.data.data.paging.totalPage
          state.selectedServices = null
        })
        .addCase(getDetail.fulfilled, (state, action) => {
          state.selectedServices = action.payload.data.output
        })

  }
})

export default appServicesSlice.reducer
