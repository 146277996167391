// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import url from "../../../configs/pathConfig"

export const getDataAboutUs = createAsyncThunk('appCompanyProfile/getData', async () => {
  const response = await axios.get(`${url.be}/about-us`)
  return {
    data: response.data
  }
})

export const getDataContactUs = createAsyncThunk('appCompanyProfile/getDataContactUs', async () => {
  const response = await axios.get(`${url.be}/contact-us`)
  return {
    data: response.data
  }
})

export const getDataHeroes = createAsyncThunk('appCompanyProfile/getDataHeroes', async () => {
  const response = await axios.get(`${url.be}/heroes`)
  return {
    data: response.data
  }
})

export const getDataHeaderLogo = createAsyncThunk('appCompanyProfile/getDataHeaderLogo', async () => {
  const response = await axios.get(`${url.be}/header-logo`)
  return {
    data: response.data
  }
})

export const appNodesSlice = createSlice({
  name: 'appCompanyProfile',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    dataAboutUs: null,
    dataContactUs: null,
    dataHeroes: null,
    dataHeaderLogo: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getDataAboutUs.fulfilled, (state, action) => {
          state.dataAboutUs = action.payload.data.data.output

          state.dataContactUs = null
          state.dataHeroes = null
          state.dataHeaderLogo = null
        })
        .addCase(getDataContactUs.fulfilled, (state, action) => {
          state.dataContactUs = action.payload.data.data.output

          state.dataAboutUs = null
          state.dataHeroes = null
          state.dataHeaderLogo = null
        })
        .addCase(getDataHeroes.fulfilled, (state, action) => {
          state.dataHeroes = action.payload.data.data.output

          state.dataContactUs = null
          state.dataAboutUs = null
          state.dataHeaderLogo = null
        })
        .addCase(getDataHeaderLogo.fulfilled, (state, action) => {
          state.dataHeaderLogo = action.payload.data.data.output

          state.dataContactUs = null
          state.dataAboutUs = null
          state.dataHeroes = null

        })
  }
})

export default appNodesSlice.reducer
