import CryptoJs from 'crypto-js'

// ** Custom Hooks
import useJwt from '@src/auth/jwt/useJwt'
const config = useJwt.jwtConfig

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { year: 'numeric', month: 'numeric', day: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateTime = (value, formatting = { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-GB', formatting).format(new Date(value)).replace(",", "")
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole.toLocaleUpperCase() === 'ADMIN') return '/'
  if (userRole.toLocaleUpperCase() === 'CLIENT') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#ac0d0d', // for selected option bg-color
    neutral10: '#ac0d0d', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const parseAbilityList = abilityList => {
  const ability = []
  for (let i = 0; i < abilityList.length; i++) {
    const a = abilityList[i]
    ability.push({action: a})
  }
  return ability
}

export const hashMd5 = (str) => {
  return CryptoJs.MD5(str).toString()
}

export const encrypt = (data) => {
  return CryptoJs.AES.encrypt(JSON.stringify(data), config.secretKey).toString()
}

export const decrypt = (data) => {
  return CryptoJs.AES.decrypt(data, config.secretKey).toString(CryptoJs.enc.Utf8)
}

export const timeDistance = (date) => {
  let distance = Math.abs(date)
  const hours = Math.floor(distance / 3600)
  distance -= hours * 3600
  const minutes = Math.floor(distance / 60)
  distance -= minutes * 60
  const seconds = Math.floor(distance)
  return `${hours}:${(`0${  minutes}`).slice(-2)}:${(`0${  seconds}`).slice(-2)}`
}

export const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1)
}
