// const baseUriLogin = "http://localhost:8082" // uri local
// const baseUriAdmin = "http://localhost:8083" // uri local
const baseImage = "https://img.metalglassindo.com"

const baseUriLogin = "https://auth.metalglassindo.com" // uri local
const baseUriAdmin = "https://api2.metalglassindo.com" // uri local
// eslint-disable-next-line no-unused-vars
const url = {
    be : `${baseUriAdmin}/api/be`,
    fe : `${baseUriAdmin}/api/fe`,
    auth : `${baseUriLogin}/api`,
    image: `${baseImage}`
}

export default url
