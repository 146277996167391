// ** Auth Endpoints
import url from "../../../configs/pathConfig"

export default {
  loginEndpoint: `${url.auth}/login`,
  getDetailEndpoint: `${url.auth}/detail`,
  registerEndpoint: `${url.auth}/register`,
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageExpiredToken: 'expiresAt',
  secretKey: 'e9cdbee489c320daecf670249383f516'
}
